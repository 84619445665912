/**
 * Profile component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";

const EXPERIENCES = [
  {
    title: "Frontend Engineer at Lenkie",
    id: "lenkie",
    link: "https://lenkie.com/",
    description:
      "Working with the following: React, Redux, Typescript, AWS, Docker.",
  },
]

const PAST_EXPERIENCES = [
  {
    title: "Lead Engineer at Wild Fusions",
    id: "wildfusion",
    link: "https:/wildfusions.com/",
    description:
      "Worked with the following: Laravel, React, Redux, Vue, Postgres, NestJS, AWS, Typescript, Git.",
  },
  {
    title: "Lead Engineer at Loanspot",
    id: "loanspot",
    link: "https://loanspot.africa/",
    description:
      "Worked with the following: React, Redux, Vue, Postgres, NestJS, AWS, Typescript, Git.",
  },
  {
    title: "Contract Software Development for Budweiser",
    id: "budweiser",
    link: "https://budweisersmoothcrowncork.ng/",
    description:
      "Developed bespoke applications to cater for ongoing brand operations and activations within Nigeria. Used Laravel, Nuxt, Postgres, Digitalocean, Redis, Git.",
  },
  {
    title: "Software Engineer at Andela",
    id: "andela",
    link: "https://andela.com/",
    description:
      "Was part of a distributed team, delivering in-house and client specific applications. Worked with: React, NodeJS, MongoDB, Postgres, SASS, Git.",
  },
]

const Experience = props => {
  return (
    <Layout>
      <Seo title="Experience" />
      <div id="experience">
        <legend>Experience</legend>
        <details open>
          <summary>Current Experience:</summary>
          <ul className="experience-list">
            {EXPERIENCES.map((e, i) => (
              <li key={i}>
                <a
                  id={e.id}
                  rel="noreferrer"
                  target="_blank"
                  href={e.link}
                >
                  {e.title}
                </a>{" "}
                <br />- {e.description}
              </li>
            ))}
          </ul>
        </details>
        <details>
          <summary>Past Experience:</summary>
          <ul className="experience-list past">
            {PAST_EXPERIENCES.map((e, i) => (
              <li key={i}>
                <a
                  id={e.id}
                  rel="noreferrer"
                  target="_blank"
                  href={e.link}
                >
                  {e.title}
                </a>{" "}
                <br />- {e.description}
              </li>
            ))}
          </ul>
        </details>
      </div>
    </Layout>
  )
}

export default Experience
